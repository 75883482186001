import {
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../../../services";
import { IModalAddPermissionsProps } from "./types";

export const ModalAddPermission: React.FC<IModalAddPermissionsProps> = ({
  hidden,
  onDismiss,
}) => {
  const [name, setName] = useState<string | undefined>("");
  const [description, setDescription] = useState<string | undefined>("");
  const [platform, setPlatform] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      const data = {
        name,
        description: `${description} (${platform})`,
      };

      await api.permission().post(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error in create permission");
    }
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.close,
        title: "Create Permission",
        subText: "This sections is used for create new permissions",
      }}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <TextField
          placeholder="Description"
          value={description}
          onChange={(_, text) => setDescription(text)}
        />
        <TextField
          placeholder="Key permission"
          value={name}
          onChange={(_, text) => setName(text)}
        />
        <Dropdown
          options={[
            { key: "1", text: "APP", id: "APP" },
            { key: "2", text: "WEB", id: "WEB" },
          ]}
          onChange={(_, option) => setPlatform(option?.id)}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton onClick={handleSubmit}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
      </DialogFooter>
    </Dialog>
  );
};
