import { IImageStyles, IStackStyles } from "@fluentui/react";

export const styles: Record<string, Partial<IStackStyles>> = {
  container: {
    root: {
      display: "flex",
      width: "100%",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  containerContent: {
    root: {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      width: 700,
      alignItems: "center",
    },
  },
};

export const imageStyle: Partial<IImageStyles> = {
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
};
