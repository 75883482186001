import { ISearchBoxStyles, IStackStyles, ITextStyles } from "@fluentui/react";

export const container: Partial<IStackStyles> = {
  root: {
    width: "980px",
    margin: "32px auto !important",
  },
};

export const content: Partial<IStackStyles> = {
  root: {
    alignSelf: "",
  },
};

export const textError: Partial<ITextStyles> = {
  root: {
    color: "rgb(164, 38, 44)",
  },
};
