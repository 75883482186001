import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Stack, Spinner, Text } from "@fluentui/react";
import { HeaderList } from "./components";
import { IPropsList } from "./types";
import * as styles from "./styles";
import { ItemList } from "./components";
import { IProductsAndCategory } from "../../../../../commonTypes";

export interface IHandleList {
  clearSelection: () => void;
}

const List = forwardRef<IHandleList, IPropsList>(
  ({ data, onSelect, selectedItems }, ref) => {
    const [end, setEnd] = useState(0);
    const [selected, setSelected] = useState<any[]>([]);

    const clearSelection = useCallback(() => setSelected([]), []);

    useEffect(() => {
      if (selectedItems) {
        setSelected(selectedItems);
      }
    }, [selectedItems]);

    useImperativeHandle(ref, () => {
      return {
        clearSelection,
      };
    });

    useEffect(() => {
      if (data) {
        setEnd(data?.length < 50 ? data?.length : 50);
      }
    }, [data]);

    function increaseEndSlice() {
      if (!data) return null;

      const total = data.length;
      const difference = total - end > 50 ? end + 50 : end + (total - end);

      setEnd((prev) => (prev >= total ? total : difference));
    }

    function handleScroll(e?: React.UIEvent<HTMLElement, globalThis.UIEvent>) {
      if (!e) {
        return null;
      }
      const scrollHeight = e?.currentTarget.scrollHeight;
      const offsetScroll = e?.currentTarget.offsetHeight;

      const totalHeightScroll = scrollHeight - offsetScroll;

      const eightyPercent = totalHeightScroll * 0.8;

      if (e.currentTarget.scrollTop > eightyPercent) {
        increaseEndSlice();
      }
    }

    const checkItem = useCallback(
      (item?: IProductsAndCategory) => {
        const prevValue = [...selected];

        const index = selected.findIndex((o) => o?.id === item?.id);

        if (index !== -1) {
          prevValue.splice(index, 1);
        } else {
          prevValue.push({ ...item, quantity: 1 });
        }

        setSelected(prevValue);

        return prevValue;
      },
      [selected]
    );

    const onChangeQuantity = useCallback(
      (id: string, quantity?: string) => {
        const prevValue = [...selected];

        const index = selected.findIndex((o) => o?.id === id);

        prevValue[index].quantity = quantity;

        onSelect && onSelect(prevValue);

        setSelected(prevValue);
      },
      [onSelect, selected]
    );

    const renderItems = useCallback(() => {
      if (!data) {
        return <Spinner />;
      }

      return data.slice(0, end).map((item, index) => (
        <ItemList
          key={item.id}
          item={item}
          onChangeQuantity={(quantity) => onChangeQuantity(item.id, quantity)}
          quantity={selected.find((o) => o.id === item.id)?.quantity}
          onSelect={(selectedItem) => {
            const items = checkItem(selectedItem);

            onSelect && onSelect(items);
          }}
          selected={selected?.some((o) => o?.id === item?.id)}
        />
      ));
    }, [checkItem, data, end, onChangeQuantity, onSelect, selected]);

    return (
      <Stack styles={styles.stylesContainer}>
        <Stack
          styles={styles.stylesListContainer}
          tokens={styles.tokensContainer}
          onScroll={handleScroll}
        >
          <HeaderList />
          {renderItems()}
        </Stack>
        <Stack styles={styles.containerTextNumber}>
          <Text>{`Displaying ${end} of ${data?.length}`}</Text>
        </Stack>
      </Stack>
    );
  }
);

export { List };
