import React from "react";
import { IconButton, Stack, Text } from "@fluentui/react";

interface Props {
  text: string;
  remove: () => void;
}

const ItemUnit: React.FC<Props> = ({ text, remove }) => {
  return (
    <Stack
      styles={{ root: { alignItems: "center" } }}
      horizontal
      tokens={{ childrenGap: 8 }}
    >
      <Stack.Item grow={3}>
        <Text>{text}</Text>
      </Stack.Item>
      <Stack.Item grow={3}>
        <IconButton
          onClick={remove}
          iconProps={{
            iconName: "Delete",
            styles: { root: { color: "rgb(255, 0, 0)" } },
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export { ItemUnit };
