import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IKits } from "../../commonTypes";
import { api } from "../../services";

export function useKits() {
  const [state, setState] = useState<IKits[]>();
  const [isLoading, setIsloading] = useState(false);

  const getProducts = useCallback(async () => {
    try {
      setIsloading(true);

      const { data } = await api.kit().get();

      if (data) {
        setState(data);
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      toast.error("Error in get data produtcs");
    }
  }, [setState]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return { kits: state, isLoading, setState };
}
