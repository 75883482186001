import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string().required("Field is required"),
  userName: Yup.string().required("Field is required"),
  password: Yup.string(),
  acl: Yup.object().shape({
    roles: Yup.array().of(Yup.string()).min(1).required("Field is required"),
    permissions: Yup.array(Yup.string())
      .min(1, "At least one permission is required.")
      .required("Field is required"),
  }),
});
