import React, { useState } from "react";
import {
  Stack,
  Text,
  TextField,
  Separator,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { api } from "../../services";
import { IDataRegisterProcessWork } from "../../commonTypes";
import { useFormik } from "formik";
import schema from "./schema";
import { container, content } from "./styles";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router";
import { AddCategory } from "../../components/AddCategory";

const ProcessWorkRegister: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);

  const navigate = useNavigate();

  const { setFieldValue, values, errors, handleSubmit } =
    useFormik<IDataRegisterProcessWork>({
      initialValues: {} as IDataRegisterProcessWork,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          await api.work().post(data);

          setLoading(false);
          toast.success("Success !!", {
            onClose: () => navigate("/process"),
            autoClose: 1000,
          });
        } catch (error) {
          setLoading(false);
          let message;

          if (axios.isAxiosError(error)) {
            //@ts-ignore
            message = error?.response?.data?.message;
          }

          toast.error(
            `Error trying to create product${
              message ? `\nMessage: ${message}` : ""
            }`
          );
        }
      },
      validationSchema: schema,
    });

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Process Work Register</Text>
        <TextField
          value={values?.process}
          onChange={(_, value) => {
            setFieldValue("process", value);
          }}
          errorMessage={errors?.process}
          placeholder="Process Name"
        />
        <Separator />
        <PrimaryButton disabled={loading} onClick={() => handleSubmit()}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
      </Stack>
      {!hidden && <AddCategory hidden={hidden} setHidden={setHidden} />}
    </Stack>
  );
};

export { ProcessWorkRegister };
