import React from "react";
import { Alert, refAlert } from "./components";
import { Routes } from "./routes";
import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./context";

export const App: React.FC = () => {
  return (
    <AuthContextProvider>
      <Routes />
      <Alert ref={refAlert} />
      <ToastContainer theme="colored" />
    </AuthContextProvider>
  );
};
