import { createContext, useCallback, useState } from "react";
import { NavigateFunction } from "react-router";
import { toast } from "react-toastify";
import { IUser } from "../../commonTypes";
import { api } from "../../services";

interface IAuthContext {
  user?: IUser;
  login: (userData: any, navigate: NavigateFunction) => void;
  loading: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>();
  const [loading, setLoading] = useState(false);

  const login = useCallback(async (userData: any, navigate) => {
    try {
      setLoading(true);
      const { data } = await api.login().post(userData);

      if (data) {
        setUser(data);
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error in login");
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
