import React, { useContext } from "react";
import {
  Stack,
  Text,
  TextField,
  Image,
  ImageFit,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import BackImage from "../../assets/back_image.jpg";
import logImage from "../../assets/logo.png";
import { styles, imageStyle } from "./styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context";

interface ILoginValues {
  userName: string;
  password: string;
}

const schema = Yup.object().shape({
  userName: Yup.string().required("Field required"),
  password: Yup.string().required("Field required"),
});

const Login: React.FC = () => {
  const { login, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  const { setFieldValue, errors, handleSubmit } = useFormik<ILoginValues>({
    initialValues: {} as ILoginValues,
    onSubmit: (data) => {
      login(data, navigate);
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: schema,
  });

  return (
    <Stack styles={styles.container}>
      <Image
        imageFit={ImageFit.centerCover}
        styles={imageStyle}
        src={BackImage}
      />
      <Stack
        tokens={{ padding: 16 }}
        horizontal
        styles={styles.containerContent}
      >
        <Stack tokens={{ padding: 16 }}>
          <Image width={200} height={235} src={logImage} />
        </Stack>
        <Stack.Item grow={1}>
          <Stack tokens={{ childrenGap: 32, padding: 32 }}>
            <Text variant="xxLarge">Login</Text>
            <TextField
              errorMessage={errors.userName}
              onChange={(_, value) => setFieldValue("userName", value)}
              placeholder="User Name:"
            />
            <TextField
              type="password"
              errorMessage={errors.password}
              onChange={(_, value) => setFieldValue("password", value)}
              placeholder="Password:"
            />
            <PrimaryButton
              disabled={loading}
              onClick={() => handleSubmit()}
              text="LOGIN"
            >
              {loading && <Spinner />}
            </PrimaryButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export { Login };
