import { IStackStyles } from "@fluentui/react";

export const stylesContainer: Partial<IStackStyles> = {
  root: {
    backgroundColor: "white",
    borderRadius: 5,
    overflow: "hidden",
  },
};

export const stylesListContainer: Partial<IStackStyles> = {
  root: {
    overflowY: "scroll",
    height: "50vh",
    overflowX: "hidden",
    backgroundColor: "white",
  },
};

export const containerTextNumber: Partial<IStackStyles> = {
  root: {
    padding: 8,
  },
};

export const tokensContainer = {
  padding: 8,
};
