import React, { useState } from "react";
import { IconButton, Stack, TextField } from "@fluentui/react";

interface Props {
  onAdd: (text: string) => void;
}

const MakeUnit: React.FC<Props> = ({ onAdd }) => {
  const [text, setText] = useState<string | undefined>();

  function handleAdd() {
    if (!text) {
      return console.log("teste");
    }

    onAdd(text);
  }

  return (
    <Stack grow horizontal tokens={{ childrenGap: 16 }}>
      <Stack.Item grow={1}>
        <TextField onChange={(_, value) => setText(value)} placeholder="Unit" />
      </Stack.Item>
      <Stack.Item grow={0.5}>
        <IconButton
          onClick={handleAdd}
          iconProps={{ iconName: "Add" }}
          text="Ola"
        />
      </Stack.Item>
    </Stack>
  );
};

export { MakeUnit };
