import React, { useCallback, useEffect, useState } from "react";
import {
  DetailsList,
  IStackStyles,
  SearchBox,
  Stack,
  Text,
  SelectionMode,
  IColumn,
  Icon,
  IconButton,
  TooltipHost,
  Sticky,
  IDetailsHeaderProps,
  DetailsRow,
  IDetailsRowProps,
} from "@fluentui/react";
import { api } from "../../services";
import { buttonDisable, buttonRestore, list } from "./styles";
import { useNavigate } from "react-router";
import { show } from "../../components";
import { toast } from "react-toastify";

const container: Partial<IStackStyles> = {
  root: {
    width: "980px",
    margin: "32px auto !important",
  },
};

const rowItem: Partial<IStackStyles> = {
  root: {
    alignItems: "center",
  },
};

interface IItem {
  id: string;
  process: string;
  deleted: string | null;
}

const ProcessWork: React.FC = () => {
  const [process, setWorkProcess] = useState<IItem[] | undefined>();
  const [filterTerm, setFilterTerm] = useState<string | undefined>();

  const navigate = useNavigate();

  function handleEdit(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to edit?",
      onPositiveButton: () => {
        navigate(
          `/edit/process/${id}/${
            process?.find((item) => item.id === id)?.process
          }`
        );
      },
    });
  }

  function handleDisable(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to state this item?",
      onPositiveButton: async () => {
        try {
          await api.work().deleteById(id);
          const oldProcess = [...(process || [])];

          oldProcess.forEach((product) => {
            if (product.id === id && !product.deleted) {
              product.deleted = new Date().toLocaleDateString();
            } else if (product.id === id && product.deleted) {
              product.deleted = null;
            }
          });

          setWorkProcess(oldProcess);
        } catch (error) {
          console.log(error);
          toast.error("Error, try again later.");
        }
      },
    });
  }

  const columns: IColumn[] = [
    {
      key: "process",
      name: "Process Name",
      minWidth: 200,
      fieldName: "process",
      onRender: (item: any) => {
        return (
          <Stack styles={rowItem} tokens={{ childrenGap: 16 }} horizontal>
            <Icon iconName="Processing" />
            <Text>{item?.process}</Text>
          </Stack>
        );
      },
    },
    {
      key: "actions",
      name: "Actions",
      minWidth: 150,
      onRender: (item: any) => {
        return (
          <Stack horizontal styles={rowItem}>
            <TooltipHost content="Edit Process">
              <IconButton
                disabled={!!item?.deleted}
                onClick={() => handleEdit(item?.id)}
                iconProps={{ iconName: "ProductRelease" }}
              />
            </TooltipHost>
            <TooltipHost
              content={item?.deleted ? "Restore Process" : "Disable Process"}
            >
              <IconButton
                onClick={() => handleDisable(item?.id)}
                styles={item?.deleted ? buttonRestore : buttonDisable}
                iconProps={{
                  iconName: !!!item?.deleted ? "Delete" : "RemoveFromTrash",
                }}
              />
            </TooltipHost>
          </Stack>
        );
      },
    },
  ];

  const getProcess = useCallback(async () => {
    try {
      const { data } = await api.work().get();

      if (data) {
        setWorkProcess(
          data.map((item) => ({
            id: item.id,
            process: item.process,
            deleted: item.deleted,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getProcess();
  }, [getProcess]);

  function handleFilter(item: IItem) {
    if (!filterTerm) {
      return true;
    }
    const byProcessName = item.process
      .toUpperCase()
      .includes(filterTerm?.toUpperCase());

    if (byProcessName) {
      return true;
    }

    return false;
  }

  return (
    <Stack styles={container}>
      <Text variant="xxLarge">Process Work List</Text>
      <SearchBox
        onChange={(_, value) => setFilterTerm(value)}
        placeholder="Product, Category..."
      />
      {process && (
        <DetailsList
          styles={list}
          items={process.filter(handleFilter)}
          columns={columns}
          selectionMode={SelectionMode.none}
          //@ts-ignore
          onRenderRow={(props: IDetailsRowProps, defaultRender) => {
            return <DetailsRow disabled={!!props.item.deleted} {...props} />;
          }}
          isHeaderVisible={true}
          //@ts-ignore
          onRenderDetailsHeader={(
            detailsHeaderProps: IDetailsHeaderProps,
            //@ts-ignore
            defaultRender: IRenderFunction<IDetailsHeaderProps>
          ) => <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>}
        />
      )}
    </Stack>
  );
};

export { ProcessWork };
