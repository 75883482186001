import {
  Icon,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import React, { useState } from "react";

interface IUnits {
  from: string | undefined;
  to: string | undefined;
}

interface Props {
  onAdd: (units: string[]) => void;
}

const MakeMultiplesUnits: React.FC<Props> = ({ onAdd }) => {
  const [units, setUnits] = useState<IUnits>({
    from: undefined,
    to: undefined,
  });

  function handleAdd() {
    const unistArray = [];
    if (units.from && units.to) {
      for (let i = Number(units.from); i <= Number(units.to); i += 1) {
        unistArray.push(i.toString().padStart(3, "0"));
      }

      onAdd(unistArray);
    }
  }

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Text>Units</Text>
        <TooltipHost
          styles={{ root: { alignItems: "center", display: "flex" } }}
          content="Always from smallest to largest"
        >
          <Icon iconName="Info" />
        </TooltipHost>
      </Stack>
      <Stack grow horizontal tokens={{ childrenGap: 8 }}>
        <Stack.Item grow={1}>
          <TextField
            value={units.from ? units.from.replace(/\D/g, "") : ""}
            onChange={(_, value) => {
              if (value !== undefined) {
                setUnits((prev) => ({
                  ...prev,
                  from: value.replace(/\D/g, ""),
                }));
              }
            }}
            placeholder="From"
          />
        </Stack.Item>
        <Stack.Item grow={1}>
          <TextField
            onChange={(_, value) => {
              if (value !== undefined) {
                setUnits((prev) => ({
                  ...prev,
                  to: value.replace(/\D/g, ""),
                }));
              }
            }}
            placeholder="To"
          />
        </Stack.Item>
      </Stack>
      <PrimaryButton onClick={handleAdd}>ADD UNITS</PrimaryButton>
    </Stack>
  );
};

export { MakeMultiplesUnits };
