/* eslint-disable no-throw-literal */
import axios, { AxiosError } from "axios";
import {
  IPermission,
  ICategoryAndProducts,
  IRole,
  IUser,
  IProductsAndCategory,
  IClient,
  IProcessWork,
  IKits,
} from "../commonTypes";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3300"
    : "https://api.team-jbf.inetsistemas.com.br";

class Api {
  private readonly baseApi = axios.create({ baseURL: url });
  permission() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IPermission[]>("/permission/get");
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: (data: any) => {
        try {
          return this.baseApi.post("/permission/create", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  category() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<ICategoryAndProducts[]>("/category/get");
        } catch (error) {
          throw error as AxiosError;
        }
      },
      put: async (data: any, id?: string) => {
        try {
          return this.baseApi.put(`/category/update/${id}`, { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: async (data: any) => {
        try {
          return this.baseApi.post("/category/create", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      deleteById: async (id?: string) => {
        try {
          return this.baseApi.delete(`/category/delete/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  product() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IProductsAndCategory[]>("/product/get");
        } catch (error) {
          throw error as AxiosError;
        }
      },
      getById: async (id: string) => {
        try {
          return this.baseApi.get<IProductsAndCategory>(`/product/get/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: async (data: any) => {
        try {
          return this.baseApi.post("/product/create", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      put: async (data: any, id?: string) => {
        try {
          return this.baseApi.put(`/product/update/${id}`, { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      deleteById: async (id?: string) => {
        try {
          return this.baseApi.delete(`/product/delete/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  login() {
    return {
      post: (data: any) => {
        try {
          return this.baseApi.post<IUser>("/login", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  user() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IUser[]>("/users/get", {
            params: { withDeleted: true },
          });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      getById: async (id: string) => {
        try {
          return this.baseApi.get<IUser>(`/users/get/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: async (data: any) => {
        try {
          return this.baseApi.post<any, { message: string }>("/users/create", {
            ...data,
          });
        } catch (error) {
          throw error;
        }
      },
      put: async (data: any, id?: string) => {
        try {
          return this.baseApi.put(`/users/update/${id}`, { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      deleteById: async (id?: string) => {
        try {
          return this.baseApi.delete(`/users/delete/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  client() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IClient[]>("/client/get", {
            params: { withDeleted: true },
          });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      getById: async (id: string) => {
        try {
          return this.baseApi.get<IClient>(`/client/get/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: async (data: any) => {
        try {
          return this.baseApi.post("/client/create", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      put: async (data: any, id?: string) => {
        try {
          return this.baseApi.put(`/client/update/${id}`, { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      deleteById: async (id?: string) => {
        try {
          return this.baseApi.delete(`/client/delete/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }
  role() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IRole[]>("/role/get");
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  work() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IProcessWork[]>("/work/process/get");
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: async (data: any) => {
        try {
          return this.baseApi.post("/work/process/create", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      put: async (data: any, id?: string) => {
        try {
          return this.baseApi.put(`/work/process/update/${id}`, { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      deleteById: async (id?: string) => {
        try {
          return this.baseApi.delete(`/work/process/delete/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }

  kit() {
    return {
      get: async () => {
        try {
          return this.baseApi.get<IKits[]>("/kit/get", {
            params: {
              withDeleted: true,
            },
          });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      getById: async (id: string) => {
        try {
          return this.baseApi.get<IClient>(`/client/get/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
      post: async (data: any) => {
        try {
          return this.baseApi.post("/kit/create", { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      put: async (data: any, id?: string) => {
        try {
          return this.baseApi.put(`/kit/update`, { ...data });
        } catch (error) {
          throw error as AxiosError;
        }
      },
      deleteById: async (id?: string) => {
        try {
          return this.baseApi.delete(`/kit/delete/${id}`);
        } catch (error) {
          throw error as AxiosError;
        }
      },
    };
  }
}

export const api = new Api();
