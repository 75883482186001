import { createRoot } from "react-dom/client";
import { App } from "./App";
import { mergeStyles, initializeIcons } from "@fluentui/react";
import "react-toastify/dist/ReactToastify.css";

mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

initializeIcons();

const container = document.getElementById("app");

//@ts-ignore
const root = createRoot(container);
root.render(<App />);
