import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Text,
  TextField,
  Separator,
  Checkbox,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { api } from "../../services";
import { IDataRegisterClient } from "../../commonTypes";
import { useFormik } from "formik";
import schema from "./schema";
import {
  container,
  content,
  listUnits,
  rowAddUnits,
  textError,
} from "./styles";
import { MakeMultiplesUnits } from "./components/MakeMultiplesUnits";
import { MakeUnit } from "./components/MakeUnit";
import { ItemUnit } from "./components/ItemUnit";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ClientEdit: React.FC = () => {
  const [multiples, setMultiples] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const { setFieldValue, values, errors, handleSubmit } =
    useFormik<IDataRegisterClient>({
      initialValues: { name: "" } as IDataRegisterClient,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          await api.client().put(data, id);

          setLoading(false);
          toast.success("Success !!", {
            autoClose: 1000,
            onClose: () => navigate("/clients"),
          });
        } catch (error) {
          setLoading(false);
          toast.error("Error, try again later");
        }
      },
      validationSchema: schema,
    });

  const getClient = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      const { data } = await api.client().getById(id);

      setFieldValue("name", data.name);
      setFieldValue("address", data.address);
      setFieldValue(
        "units",
        data.units.map((unit) => unit.numberOf)
      );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Client Editing</Text>
        <TextField
          value={values?.name}
          onChange={(_, value) => {
            setFieldValue("name", value);
          }}
          errorMessage={errors.name}
          placeholder="Name"
        />
        <TextField
          value={values?.address}
          onChange={(_, value) => {
            setFieldValue("address", value);
          }}
          errorMessage={errors.address}
          placeholder="Address"
        />
        <Stack styles={rowAddUnits} horizontal tokens={{ childrenGap: 16 }}>
          <Text variant="large">Add Units</Text>
          <Checkbox
            label="Multiples"
            onChange={(_, value) => setMultiples(!!value)}
          />
        </Stack>
        {multiples ? (
          <MakeMultiplesUnits
            onAdd={(units) => {
              setFieldValue("units", [...(values?.units || []), ...units]);
            }}
          />
        ) : (
          <MakeUnit
            onAdd={(text) => {
              if (values?.units && values.units.some((unit) => unit === text)) {
                return;
              }
              setFieldValue("units", [...(values?.units || []), text]);
            }}
          />
        )}
        <Separator />
        <Stack wrap styles={listUnits} tokens={{ childrenGap: 8 }}>
          {values?.units &&
            values.units.map((unit) => (
              <ItemUnit
                remove={() => {
                  setFieldValue(
                    "units",
                    values.units.filter((un) => un !== unit)
                  );
                }}
                text={unit}
              />
            ))}
        </Stack>
        {errors.units && (
          <Text styles={textError} variant="small">
            {errors.units}
          </Text>
        )}
        <PrimaryButton disabled={loading} onClick={() => handleSubmit()}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

export { ClientEdit };
