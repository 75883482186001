import { CSSProperties } from "react";
import styled from "styled-components";

export const tokensContainer = {
  padding: 4,
};

export const Container = styled.div`
  display: grid;
  padding: 4px;
  grid-template-columns: repeat(2, 1fr);
  padding: 4;
  @media screen and (max-width: 768px) {
    grid-template-columns: 0.1fr repeat(3, 1fr);
  }
`;

export const textColum1: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
  fontWeight: "600",
};

export const textColum2: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
  fontWeight: "600",
};

export const textColum3: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
  fontWeight: "600",
};

export const textColum4: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontWeight: "600",
};
