import React, {
  createRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

interface IOptionsAlert extends IDialogContentProps {
  onPositiveButton?: () => void;
  onNegativeButton?: () => void;
}

export interface IHandlesAlert {
  show: (options: IOptionsAlert) => void;
}

export const refAlert = createRef<IHandlesAlert>();

export function show(options: IOptionsAlert) {
  refAlert.current?.show(options);
}

const Alert = forwardRef<IHandlesAlert>((props, ref) => {
  const [hidden, { setFalse, setTrue }] = useBoolean(true);

  const [state, setState] = useState<IOptionsAlert>({
    type: DialogType.normal,
  });

  const show = useCallback(
    (options: IOptionsAlert) => {
      setState(options);
      setFalse();
    },
    [setFalse]
  );

  useImperativeHandle(ref, () => {
    return {
      show,
    };
  });

  if (hidden) {
    return null;
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={setTrue}
      dialogContentProps={state}
      modalProps={{
        dragOptions: {
          moveMenuItemText: "Move",
          closeMenuItemText: "Close",
          menu: ContextualMenu,
          keepInBounds: true,
        },
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            state.onPositiveButton && state.onPositiveButton();
            setTrue();
          }}
          text="Yes"
        />
        <DefaultButton
          onClick={() => {
            state.onNegativeButton && state.onNegativeButton();
            setTrue();
          }}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  );
});

export { Alert };
