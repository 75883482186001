import React, { useContext, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { Drawer } from "../components";
import {
  UsersRegister,
  Users,
  ProductRegister,
  Products,
  ClientRegister,
  Clients,
  UsersEdit,
  ProductEdit,
  ClientEdit,
  Login,
  ProcessWorkRegister,
  ProcessWork,
  ProcessWorkEdit,
} from "../screens";
import { AuthContext } from "../context";
import { KitsCreate } from "../screens/Kits/Create";
import { Kits } from "../screens/Kits/List";
import { KitsEdit } from "../screens/Kits/Edit";

const RoutesApp: React.FC = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  return (
    <Stack horizontal>
      {user && <Drawer />}
      <Routes>
        <Route path="/" element={<Navigate to="/clients" />} />

        <Route path="/users" element={<Users />} />
        <Route path="/edit/user/:id" element={<UsersEdit />} />
        <Route path="/register/users" element={<UsersRegister />} />

        <Route path="/products" element={<Products />} />
        <Route path="/edit/product/:id" element={<ProductEdit />} />
        <Route path="/register/products" element={<ProductRegister />} />

        <Route path="/kits" element={<Kits />} />
        <Route path="/edit/kits/:id" element={<KitsEdit />} />
        <Route path="/register/kits" element={<KitsCreate />} />

        <Route path="/clients" element={<Clients />} />
        <Route path="/edit/client/:id" element={<ClientEdit />} />
        <Route path="/register/clients" element={<ClientRegister />} />

        <Route path="/process" element={<ProcessWork />} />
        <Route path="/register/process" element={<ProcessWorkRegister />} />
        <Route
          path="/edit/process/:id/:process"
          element={<ProcessWorkEdit />}
        />

        <Route path="/login" element={<Login />} />
      </Routes>
    </Stack>
  );
};

export { RoutesApp };
