import React, { memo } from "react";
import { IconButton, Stack, Text, TooltipHost } from "@fluentui/react";
import { IPropsItem } from "./types";
import * as styles from "./styles";

const ItemList: React.FC<IPropsItem> = memo(
  ({ item, onDisable, onPressEdit }) => {
    const name = item.name;

    return (
      <styles.Container isDisabled={!!item?.deleted}>
        <Text title={name} style={styles.textColum1}>
          {name}
        </Text>
        <Text style={styles.textColum2}>{item.unit}</Text>
        <Text style={styles.textColum3}>{item.category.name}</Text>
        <Stack horizontal>
          <TooltipHost content="Edit Product">
            <IconButton
              disabled={!!item?.deleted}
              onClick={onPressEdit}
              iconProps={{ iconName: "ProductRelease" }}
            />
          </TooltipHost>
          <TooltipHost
            content={item?.deleted ? "Restore Product" : "Disable Product"}
          >
            <IconButton
              onClick={onDisable}
              styles={
                item?.deleted ? styles.buttonRestore : styles.buttonDisable
              }
              iconProps={{
                iconName: !!!item?.deleted ? "Delete" : "RemoveFromTrash",
              }}
            />
          </TooltipHost>
        </Stack>
      </styles.Container>
    );
  }
);

export { ItemList };
