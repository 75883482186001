import {
  Checkbox,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
  SearchBox,
  Stack,
} from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { IProductsAndCategory } from "../../../../../commonTypes";
import { compareWord } from "../../../../../helpers";
import { useProducts } from "../../../../../hooks/useProducts";
import { List } from "../List";
import { IPropsModal } from "./types";

const modalProps: Partial<IModalProps> = {
  isBlocking: false,
};

export const ModalList: React.FC<IPropsModal> = ({
  hidden,
  onSelect,
  onRequestClose,
  selectedItems,
}) => {
  const [showDisabled, setShowDisabled] = useState<boolean | undefined>(false);
  const { products } = useProducts();
  const [filterTerm, setFilterTerm] = useState<string | undefined>("");

  const title = useCallback(
    () => (
      <Stack tokens={{ childrenGap: 16, padding: 8 }}>
        <SearchBox
          placeholder="Product, Category..."
          value={filterTerm}
          onChange={(_, text) => setFilterTerm(text)}
        />
        <Checkbox
          label="Show disabled items"
          checked={showDisabled}
          onChange={(_, state) => setShowDisabled(!!state)}
        />
      </Stack>
    ),
    [filterTerm, showDisabled]
  );

  const dialogContentProps: Partial<IDialogContentProps> = {
    type: DialogType.close,
    title: title(),
    styles: {
      header: {
        height: 100,
      },
    },
  };

  function filterDisabled(item: IProductsAndCategory) {
    if (showDisabled) {
      return item.deleted ? true : true;
    }
    return item.deleted ? false : true;
  }

  function filter(item: IProductsAndCategory) {
    if (!filterTerm) {
      return true;
    }

    return compareWord([item.name, item.category.name], filterTerm);
  }

  return (
    <Dialog
      hidden={hidden}
      minWidth={740}
      maxWidth={600}
      onDismiss={() => onRequestClose && onRequestClose()}
      {...{ dialogContentProps, modalProps }}
    >
      <List
        data={products?.filter(filterDisabled)?.filter(filter)}
        onSelect={onSelect}
        selectedItems={selectedItems}
      />
    </Dialog>
  );
};
