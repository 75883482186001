import React, { useState } from "react";
import {
  IStackStyles,
  SearchBox,
  Stack,
  Text,
  Checkbox,
} from "@fluentui/react";
import { api } from "../../../services";
import { useNavigate } from "react-router";
import { show } from "../../../components";
import { toast } from "react-toastify";
import { useProducts } from "../../../hooks/useProducts";
import { IKits } from "../../../commonTypes";
import { List } from "./components/List";
import { compareWord } from "../../../helpers";
import { useKits } from "../../../hooks/useKits";

const container: Partial<IStackStyles> = {
  root: {
    width: "980px",
    margin: "32px auto !important",
  },
};

const Kits: React.FC = () => {
  const { kits, setState } = useKits();
  const [showDisabled, setShowDisabled] = useState(false);

  const [filterTerm, setFilterTerm] = useState<string | undefined>("");

  const navigate = useNavigate();

  function handleEdit(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to edit?",
      onPositiveButton: () => {
        navigate(`/edit/kits/${id}`);
      },
    });
  }

  function handleDisable(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to state this item?",
      onPositiveButton: async () => {
        try {
          await api.kit().deleteById(id);
          const prevData = [...(kits || [])];
          const now = new Date().toLocaleDateString();

          const index = prevData.findIndex((item) => item.id === id);

          const isDeleted = !!prevData[index].deleted;

          prevData[index].deleted = isDeleted ? null : now;

          setState(prevData);
          toast.success("Kit disabled", { autoClose: 800 });
        } catch (error) {
          console.log(error);
          toast.error("Error in disable product, try again later.");
        }
      },
    });
  }

  function filter(item: IKits) {
    if (!filterTerm) {
      return true;
    }

    return compareWord(item.title, filterTerm);
  }

  return (
    <Stack styles={container}>
      <Text variant="xxLarge">Kits List</Text>
      <Stack tokens={{ childrenGap: 16, padding: 8 }}>
        <SearchBox
          value={filterTerm}
          onChange={(_, value) => setFilterTerm(value)}
          placeholder="Title..."
        />
        <Checkbox
          label="Show disabled items"
          checked={showDisabled}
          onChange={(_, state) => setShowDisabled(!!state)}
        />
      </Stack>
      {kits && (
        <List
          data={kits
            ?.filter((item) => {
              if (showDisabled) {
                return item.deleted ? true : true;
              }
              return item.deleted ? false : true;
            })
            .filter(filter)}
          onPressEdit={(id) => handleEdit(id)}
          onDisable={(id) => handleDisable(id)}
        />
      )}
    </Stack>
  );
};

export { Kits };
