import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../../../services";
import { ISelected } from "../../components/ModalList/types";
import schema from "./schema";

interface IData {
  title?: string;
  items: ISelected[];
}

export function useCreate() {
  const navigation = useNavigate();
  const { values, setFieldValue, setFieldError, errors, handleSubmit } =
    useFormik<IData>({
      initialValues: {
        items: [],
        title: "",
      },
      validationSchema: schema,
      validateOnChange: false,
      onSubmit: async (data) => {
        try {
          setLoading(true);

          await api.kit().post(data);

          setLoading(false);

          toast.success("Kit created", {
            autoClose: 800,
            onClose: () => {
              navigation("/kits");
            },
          });
        } catch (error) {
          setLoading(false);
          toast.error("Error in create kit");
        }
      },
    });

  const [loading, setLoading] = useState(false);
  const [hiddenModal, sethiddenModal] = useState(true);

  useEffect(() => {
    if (errors.items) {
      toast.error(errors.items, {
        autoClose: 1200,
        onClose: () => setFieldError("items", undefined),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleInput = {
    title: {
      onChange: (_: any, text: string) => setFieldValue("title", text),
      value: values.title,
      errorMessage: errors?.title,
      onFocus: () => setFieldError("title", undefined),
    },
  };

  const onSelect = (items: ISelected[]) => {
    setFieldValue("items", items);
  };

  return {
    handleCreate: handleSubmit,
    handleInput,
    loading,
    hiddenModal,
    sethiddenModal,
    items: values.items,
    onSelect,
  };
}
