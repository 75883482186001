import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Text,
  TextField,
  Dropdown,
  IDropdownOption,
  Separator,
  Checkbox,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { api } from "../../services";
import { IDataRegisterUser, IPermission } from "../../commonTypes";
import { useFormik } from "formik";
import schema from "./schema";
import { container, content, listPermissions, textError } from "./styles";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalAddPermission } from "./components/ModalAddPermission";

const UsersEdit: React.FC = () => {
  const [roles, setRoles] = useState<IDropdownOption[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [loading, setLoading] = useState(false);
  const [hiddenAddPermission, setHiddenAddPermission] = useState(true);

  const navigate = useNavigate();

  const { id } = useParams();

  const { setFieldValue, values, errors, handleSubmit } =
    useFormik<IDataRegisterUser>({
      initialValues: { name: "" } as IDataRegisterUser,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          await api.user().put(data, id);

          setLoading(false);
          toast.success("Success !!", {
            autoClose: 1000,
            onClose: () => navigate("/users"),
          });
        } catch (error) {
          setLoading(false);
          toast.error("Error, try again later !!");
        }
      },
      validationSchema: schema,
    });

  const getRoles = useCallback(async () => {
    try {
      const { data } = await api.role().get();

      if (data) {
        setRoles(
          data.map((role) => ({ key: role.id, text: role.description }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getPermission = useCallback(async () => {
    try {
      const { data } = await api.permission().get();

      console.log(data);

      if (data) {
        setPermissions(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getUser = useCallback(async () => {
    try {
      if (!id) {
        return;
      }

      const { data } = await api.user().getById(id);

      setFieldValue("name", data.name);
      setFieldValue("userName", data.userName);
      setFieldValue("acl", {
        roles: data.roles.map((item) => item.id),
        permissions: data.permissions.map((item) => item.id),
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getRoles();
    getPermission();
    getUser();
  }, [getPermission, getRoles, getUser]);

  if (!values) {
    return null;
  }

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Users Editing</Text>
        <TextField
          value={values?.name}
          onChange={(_, value) => {
            setFieldValue("name", value);
          }}
          errorMessage={errors.name}
          placeholder="Name"
        />
        <TextField
          value={values?.userName}
          onChange={(_, value) => {
            setFieldValue("userName", value);
          }}
          errorMessage={errors.userName}
          placeholder="User Name"
        />
        <TextField
          onChange={(_, value) => {
            setFieldValue("password", value);
          }}
          type="password"
          errorMessage={errors.password}
          placeholder="Password"
        />
        <Separator />
        <Dropdown
          selectedKey={values?.acl?.roles}
          onChange={(_, value) => {
            setFieldValue("acl", { ...values.acl, roles: [value?.key] });
          }}
          placeholder="Select an option"
          //@ts-ignore
          errorMessage={errors.acl?.roles}
          options={roles}
        />
        <Separator />
        <Checkbox
          onChange={(_, checked) =>
            setFieldValue(
              "acl",
              checked
                ? {
                    ...values.acl,
                    permissions: permissions.map((item) => item.id),
                  }
                : { ...values.acl, permissions: [] }
            )
          }
          label="All"
        />
        <Stack wrap styles={listPermissions} tokens={{ childrenGap: 8 }}>
          <Text variant="xLarge">WEB Permissions</Text>
          {permissions
            .filter((item) => item.description.includes("WEB"))
            .map((permission) => (
              <Checkbox
                checked={
                  !!values?.acl?.permissions?.some(
                    (item) => item === permission.id
                  )
                }
                onChange={(_, checked) =>
                  setFieldValue(
                    "acl",
                    checked
                      ? {
                          ...values.acl,
                          permissions: [
                            ...(values?.acl?.permissions || []),
                            permission.id,
                          ],
                        }
                      : {
                          ...values.acl,
                          permissions: values?.acl?.permissions.filter(
                            (item) => item !== permission.id
                          ),
                        }
                  )
                }
                key={permission.id}
                label={permission.description}
              />
            ))}
          <Separator />
          <Text variant="xLarge">APP Permissions</Text>
          {permissions
            .filter((item) => item.description.includes("APP"))
            .map((permission) => (
              <Checkbox
                checked={
                  !!values?.acl?.permissions?.some(
                    (item) => item === permission.id
                  )
                }
                onChange={(_, checked) =>
                  setFieldValue(
                    "acl",
                    checked
                      ? {
                          ...values.acl,
                          permissions: [
                            ...(values?.acl?.permissions || []),
                            permission.id,
                          ],
                        }
                      : {
                          ...values.acl,
                          permissions: values?.acl?.permissions.filter(
                            (item) => item !== permission.id
                          ),
                        }
                  )
                }
                key={permission.id}
                label={permission.description}
              />
            ))}
        </Stack>
        {errors.acl?.permissions && (
          <Text styles={textError} variant="small">
            {errors.acl?.permissions}
          </Text>
        )}
        <PrimaryButton disabled={loading} onClick={() => handleSubmit()}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
        {/* <PrimaryButton onClick={() => setHiddenAddPermission(false)}>
          CREATE PERMISSION
        </PrimaryButton> */}
      </Stack>
      <ModalAddPermission
        hidden={hiddenAddPermission}
        onDismiss={() => setHiddenAddPermission(true)}
      />
    </Stack>
  );
};

export { UsersEdit };
