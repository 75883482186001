import React from "react";
import { Text } from "@fluentui/react";
import * as Styles from "./styles";
import { IPropsHeaderList } from "./types";

const HeaderList: React.FC<IPropsHeaderList> = ({ handleSelect }) => {
  return (
    <Styles.Container>
      <Text style={Styles.textColum1}>Name Kit:</Text>
      <Text style={Styles.textColum4}>Actions</Text>
    </Styles.Container>
  );
};

export { HeaderList };
