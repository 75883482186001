import React, { useState } from "react";
import {
  Stack,
  Text,
  TextField,
  Separator,
  Checkbox,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { api } from "../../services";
import { IDataRegisterClient } from "../../commonTypes";
import { useFormik } from "formik";
import schema from "./schema";
import {
  container,
  content,
  listUnits,
  rowAddUnits,
  textError,
} from "./styles";
import { MakeMultiplesUnits } from "./components/MakeMultiplesUnits";
import { MakeUnit } from "./components/MakeUnit";
import { ItemUnit } from "./components/ItemUnit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import axios from "axios";

const ClientRegister: React.FC = () => {
  const [multiples, setMultiples] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { setFieldValue, values, errors, handleSubmit } =
    useFormik<IDataRegisterClient>({
      initialValues: { name: "" } as IDataRegisterClient,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          await api.client().post(data);

          setLoading(false);
          toast.success("Success !!", {
            onClose: () => navigate("/clients"),
            autoClose: 1000,
          });
        } catch (error) {
          setLoading(false);
          let message;

          if (axios.isAxiosError(error)) {
            //@ts-ignore
            message = error?.response?.data?.message;
          }

          toast.error(
            `Error trying to create client${
              message ? `\nMessage: ${message}` : ""
            }`
          );
        }
      },
      validationSchema: schema,
    });

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Client Register</Text>
        <TextField
          value={values.name}
          onChange={(_, value) => {
            setFieldValue("name", value);
          }}
          errorMessage={errors.name}
          placeholder="Name"
        />
        <TextField
          onChange={(_, value) => {
            setFieldValue("address", value);
          }}
          errorMessage={errors.address}
          placeholder="Address"
        />
        <Stack styles={rowAddUnits} horizontal tokens={{ childrenGap: 16 }}>
          <Text variant="large">Add Units</Text>
          <Checkbox
            label="Multiples"
            onChange={(_, value) => setMultiples(!!value)}
          />
        </Stack>
        {multiples ? (
          <MakeMultiplesUnits
            onAdd={(units) => {
              setFieldValue("units", [...(values?.units || []), ...units]);
            }}
          />
        ) : (
          <MakeUnit
            onAdd={(text) => {
              if (values?.units && values.units.some((unit) => unit === text)) {
                return;
              }
              setFieldValue("units", [...(values?.units || []), text]);
            }}
          />
        )}
        <Separator />
        <Stack wrap styles={listUnits} tokens={{ childrenGap: 8 }}>
          {values?.units &&
            values.units.map((unit) => (
              <ItemUnit
                remove={() => {
                  setFieldValue(
                    "units",
                    values.units.filter((un) => un !== unit)
                  );
                }}
                text={unit}
              />
            ))}
        </Stack>
        {errors.units && (
          <Text styles={textError} variant="small">
            {errors.units}
          </Text>
        )}
        <PrimaryButton disabled={loading} onClick={() => handleSubmit()}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

export { ClientRegister };
