import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IProductsAndCategory } from "../../commonTypes";
import { api } from "../../services";

export function useProducts() {
  const [products, setState] = useState<IProductsAndCategory[]>();
  const [isLoading, setIsloading] = useState(false);

  const getProducts = useCallback(async () => {
    try {
      setIsloading(true);

      const { data } = await api.product().get();

      if (data) {
        setState(data);
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      toast.error("Error in get data produtcs");
    }
  }, [setState]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return { products, isLoading, setState };
}
