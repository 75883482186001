import {
  DetailsList,
  DetailsRow,
  Dialog,
  DialogFooter,
  IDetailsHeaderProps,
  Stack,
  Sticky,
  SelectionMode,
  TooltipHost,
  IconButton,
  Icon,
  Text,
  IColumn,
  IModalProps,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { show } from "..";
import { api } from "../../services";
import { schema } from "./schema";
import {
  buttonDisable,
  buttonRestore,
  container,
  list,
  rowItem,
} from "./styles";

interface IItem {
  id: string;
  name: string;
  deleted: string | null;
}

interface IValues {
  name?: string;
}

const modalProps: IModalProps = {
  isBlocking: true,
  topOffsetFixed: true,
};

interface Props {
  hidden?: boolean;
  setHidden?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddCategory: React.FC<Props> = ({ hidden, setHidden }) => {
  const [categories, setCategories] = useState<IItem[]>([]);
  const [editItem, setEditItem] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const { values, errors, setFieldValue, handleSubmit, setFieldError } =
    useFormik<IValues>({
      initialValues: {},
      validationSchema: schema,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          editItem
            ? await api.category().put(data, editItem)
            : await api.category().post(data);

          await getCategories();
          setLoading(false);
        } catch (error) {
          if (axios.isAxiosError(error) && error?.response?.data) {
            //@ts-ignore
            const message = error?.response?.data?.message;

            setFieldError("name", message);
            setLoading(false);
            return;
          }
          setLoading(false);
          toast.error("Error in add new category");
        }
      },
    });

  const getCategories = useCallback(async () => {
    try {
      const { data } = await api.category().get();

      if (data) {
        setCategories(
          data.map((category) => ({
            id: category.id,
            name: category.name,
            deleted: category.deleted,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  function handleDisable(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to state this item?",
      onPositiveButton: async () => {
        try {
          await api.category().deleteById(id);
          const oldCategories = [...(categories || [])];

          oldCategories.forEach((category) => {
            if (category.id === id && !category.deleted) {
              category.deleted = new Date().toLocaleDateString();
            } else if (category.id === id && category.deleted) {
              category.deleted = null;
            }
          });

          setCategories(oldCategories);
        } catch (error) {
          console.log(error);
          toast.error("Error, try again later.");
        }
      },
    });
  }

  const columns: IColumn[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 200,
      fieldName: "name",
      onRender: (item: any) => {
        return (
          <Stack styles={rowItem} tokens={{ childrenGap: 16 }} horizontal>
            <Icon iconName="GroupedList" />
            <Text>{item?.name}</Text>
          </Stack>
        );
      },
    },
    {
      key: "actions",
      name: "Actions",
      minWidth: 150,
      onRender: (item: any) => {
        return (
          <Stack horizontal styles={rowItem}>
            <TooltipHost content="Edit Category">
              <IconButton
                onClick={() => {
                  setEditItem(item.id);
                  setFieldValue("name", item.name);
                }}
                disabled={!!item?.deleted}
                iconProps={{ iconName: "Edit" }}
              />
            </TooltipHost>
            <TooltipHost
              content={item?.deleted ? "Restore Category" : "Disable Category"}
            >
              <IconButton
                onClick={() => handleDisable(item?.id)}
                styles={item?.deleted ? buttonRestore : buttonDisable}
                iconProps={{
                  iconName: !!!item?.deleted ? "Delete" : "RemoveFromTrash",
                }}
              />
            </TooltipHost>
          </Stack>
        );
      },
    },
  ];

  return (
    <Dialog
      dialogContentProps={{
        title: editItem ? "Categories (EDIT MODE)" : "Categories",
      }}
      modalProps={modalProps}
      styles={container}
      onDismiss={() => setHidden && setHidden(true)}
      hidden={hidden}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        {categories && (
          <DetailsList
            styles={list}
            items={categories}
            columns={columns}
            selectionMode={SelectionMode.none}
            isHeaderVisible={true}
            //@ts-ignore
            onRenderRow={(props: IDetailsRowProps) => {
              return <DetailsRow disabled={!!props.item.deleted} {...props} />;
            }}
            //@ts-ignore
            onRenderDetailsHeader={
              // tslint:disable-next-line:jsx-no-lambda
              (
                detailsHeaderProps: IDetailsHeaderProps,
                //@ts-ignore
                defaultRender: IRenderFunction<IDetailsHeaderProps>
              ) => <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>
            }
          />
        )}
        <TextField
          onChange={(_, value) => setFieldValue("name", value)}
          errorMessage={errors.name}
          value={values.name}
          placeholder="Category Name:"
        />
      </Stack>

      <DialogFooter>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          {editItem && (
            <DefaultButton
              onClick={() => {
                setEditItem(undefined);
                setFieldValue("name", undefined);
              }}
              text="CANCEL EDIT"
            />
          )}
          <PrimaryButton
            text={editItem ? "EDIT" : "ADD"}
            onClick={() => handleSubmit()}
            checked
            iconProps={{ iconName: editItem ? "Edit" : "Add" }}
          >
            {loading && <Spinner />}
          </PrimaryButton>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
};

export { AddCategory };
