import React, { useCallback, useEffect, useState } from "react";
import {
  DetailsList,
  IStackStyles,
  SearchBox,
  Stack,
  Text,
  SelectionMode,
  IColumn,
  Icon,
  IconButton,
  TooltipHost,
  Sticky,
  IDetailsHeaderProps,
  DetailsRow,
} from "@fluentui/react";
import { api } from "../../services";
import { buttonDisable, buttonRestore, list } from "./styles";
import { useNavigate } from "react-router";
import { show } from "../../components";
import { toast } from "react-toastify";

const container: Partial<IStackStyles> = {
  root: {
    width: "980px",
    margin: "32px auto !important",
  },
};

const rowItem: Partial<IStackStyles> = {
  root: {
    alignItems: "center",
  },
};

interface IItem {
  id: string;
  name: string;
  userName: string;
  permission: string;
  deleted: string | null;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<IItem[] | undefined>();
  const [filterTerm, setFilterTerm] = useState<string | undefined>();
  const navigate = useNavigate();

  function handleEdit(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to edit?",
      onPositiveButton: () => {
        navigate(`/edit/user/${id}`);
      },
    });
  }

  function handleDisable(id?: string) {
    show({
      title: "Attention",
      subText: "Are you sure you want to state this item?",
      onPositiveButton: async () => {
        try {
          await api.user().deleteById(id);

          const oldUsers = [...(users || [])];

          oldUsers.forEach((user) => {
            if (user.id === id && !user.deleted) {
              user.deleted = new Date().toLocaleDateString();
            } else {
              user.deleted = null;
            }
          });

          setUsers(oldUsers);
        } catch (error) {
          console.log(error);
          toast.error("Error, try again later.");
        }
      },
    });
  }

  const columns: IColumn[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 200,
      fieldName: "name",
      onRender: (item: any) => {
        return (
          <Stack styles={rowItem} tokens={{ childrenGap: 16 }} horizontal>
            <Icon iconName="Contact" />
            <Text>{item?.name}</Text>
          </Stack>
        );
      },
    },
    {
      key: "userName",
      name: "User Name",
      minWidth: 200,
      fieldName: "userName",
    },
    {
      key: "role",
      name: "Permission",
      minWidth: 200,
      fieldName: "permission",
    },
    {
      key: "actions",
      name: "Actions",
      minWidth: 150,
      onRender: (item: any) => {
        return (
          <Stack horizontal styles={rowItem}>
            <TooltipHost content="Edit User">
              <IconButton
                disabled={!!item?.deleted}
                onClick={() => handleEdit(item?.id)}
                iconProps={{ iconName: "EditContact" }}
              />
            </TooltipHost>
            <TooltipHost
              content={item?.deleted ? "Restore User" : "Disable User"}
            >
              <IconButton
                onClick={() => handleDisable(item?.id)}
                styles={item?.deleted ? buttonRestore : buttonDisable}
                iconProps={{
                  iconName: !!!item?.deleted ? "UserRemove" : "UserFollowed",
                }}
              />
            </TooltipHost>
          </Stack>
        );
      },
    },
  ];

  const getUsers = useCallback(async () => {
    try {
      const { data } = await api.user().get();

      if (data) {
        setUsers(
          data.map((user) => ({
            id: user.id,
            name: user.name,
            userName: user.userName,
            permission: user.roles[0].description,
            deleted: user.deleted,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  function handleFilter(item: IItem) {
    if (!filterTerm) {
      return true;
    }
    const byUserName = item.userName
      .toUpperCase()
      .includes(filterTerm?.toUpperCase());
    const byName = item.name.toUpperCase().includes(filterTerm?.toUpperCase());

    if (byUserName || byName) {
      return true;
    }

    return false;
  }

  return (
    <Stack styles={container}>
      <Text variant="xxLarge">Users List</Text>
      <SearchBox
        onChange={(_, value) => setFilterTerm(value)}
        placeholder="Search"
      />
      {users && (
        <DetailsList
          styles={list}
          items={users.filter(handleFilter)}
          columns={columns}
          selectionMode={SelectionMode.none}
          isHeaderVisible={true}
          //@ts-ignore
          onRenderRow={(props: IDetailsRowProps, defaultRender) => {
            return <DetailsRow disabled={!!props.item.deleted} {...props} />;
          }}
          //@ts-ignore
          onRenderDetailsHeader={
            // tslint:disable-next-line:jsx-no-lambda
            (
              detailsHeaderProps: IDetailsHeaderProps,
              //@ts-ignore
              defaultRender: IRenderFunction<IDetailsHeaderProps>
            ) => <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>
          }
        />
      )}
    </Stack>
  );
};

export { Users };
