import React from "react";
import {
  Stack,
  Text,
  TextField,
  Separator,
  PrimaryButton,
  Spinner,
  DefaultButton,
} from "@fluentui/react";
import { container, content } from "./styles";
import { ModalList } from "./components/ModalList";
import { useEdit } from "./hooks/useEdit";

const KitsEdit: React.FC = () => {
  const {
    handleInput,
    loading,
    sethiddenModal,
    hiddenModal,
    handleCreate,
    items,
    onSelect,
  } = useEdit();

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Kit Edit</Text>
        <TextField {...(handleInput.title as any)} placeholder="Name kit:" />

        <Separator />

        <Text>total of {items?.length} items selected</Text>

        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <DefaultButton
            styles={{ root: { width: "50%" } }}
            onClick={() => sethiddenModal((prev) => !prev)}
          >
            ADD
          </DefaultButton>
          <PrimaryButton
            styles={{ root: { width: "50%" } }}
            disabled={loading}
            onClick={() => handleCreate()}
          >
            {loading ? <Spinner /> : "UPDATE"}
          </PrimaryButton>
        </Stack>
      </Stack>
      <ModalList
        hidden={hiddenModal}
        onRequestClose={() => sethiddenModal(true)}
        onSelect={onSelect}
        selectedItems={items}
      />
    </Stack>
  );
};

export { KitsEdit };
