import { IButtonStyles } from "@fluentui/react";
import { CSSProperties } from "react";
import styled, { css } from "styled-components";

export const tokensContainer = {
  padding: 4,
  childrenGap: 4,
};

export const Container = styled.div<{ isDisabled?: boolean }>`
  display: grid;
  padding: 4px;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 0.1fr repeat(3, 1fr);
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: #9e9e9e34;
      span {
        color: #96969688;
        font-style: italic;
      }
    `}
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background-color: #7e7e7e21;
        border-radius: 4px;
      }
    `}
`;

export const textColum1: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
};

export const textColum2: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
};

export const textColum3: CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: 8,
};

export const textColum4: (state?: boolean) => CSSProperties = (state) => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  backgroundColor: state ? "#acff8c" : "#ff999c8c",
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: 18,
  textAlign: "center",
  color: state ? "#28af00" : "#f82c2c",
});

export const buttonDisable: Partial<IButtonStyles> = {
  icon: {
    color: "rgb(255, 0, 0)",
  },
};

export const buttonRestore: Partial<IButtonStyles> = {
  icon: {
    color: "rgb(9, 255, 0)",
  },
};
