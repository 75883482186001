import { IStackStyles, ITextStyles } from "@fluentui/react";

export const container: Partial<IStackStyles> = {
  root: {
    width: "980px",
    margin: "32px auto !important",
  },
};

export const content: Partial<IStackStyles> = {
  root: {
    alignSelf: "",
  },
};

export const textError: Partial<ITextStyles> = {
  root: {
    color: "rgb(255, 0, 0)",
  },
};

export const listUnits: Partial<IStackStyles> = {
  root: {
    height: "30vh",
  },
};

export const rowAddUnits: Partial<IStackStyles> = {
  root: { alignItems: "center" },
};
