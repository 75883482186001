import React, { memo } from "react";
import { Checkbox, Text, TextField } from "@fluentui/react";
import { IPropsItem } from "./types";
import * as styles from "./styles";

const ItemList: React.FC<IPropsItem> = memo(
  ({ item, onSelect, selected, onChangeQuantity, quantity }) => {
    const name = item.name;

    function handleSelect(state?: boolean) {
      if (typeof onSelect === "function") {
        onSelect(item);
      }
    }

    return (
      <styles.Container isDisabled={!!item?.deleted}>
        <Checkbox
          checked={selected}
          onChange={(_, state) => handleSelect(state)}
        />
        <Text title={name} style={styles.textColum1}>
          {name}
        </Text>
        <Text style={styles.textColum2}>{item.unit}</Text>
        <Text style={styles.textColum3}>{item.category.name}</Text>
        {selected && (
          <TextField
            placeholder="Quantity"
            value={quantity}
            onChange={(_, text) => onChangeQuantity && onChangeQuantity(text)}
            type="number"
          />
        )}
      </styles.Container>
    );
  }
);

export { ItemList };
