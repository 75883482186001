import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Text,
  TextField,
  Dropdown,
  IDropdownOption,
  Separator,
  Checkbox,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import { api } from "../../services";
import { IDataRegisterUser, IPermission } from "../../commonTypes";
import { useFormik } from "formik";
import schema from "./schema";
import { container, content, listPermissions, textError } from "./styles";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import axios from "axios";
import { show } from "../../components";

const UsersRegister: React.FC = () => {
  const [roles, setRoles] = useState<IDropdownOption[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { setFieldValue, values, errors, handleSubmit } =
    useFormik<IDataRegisterUser>({
      initialValues: { name: "" } as IDataRegisterUser,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          await api.user().post(data);

          setLoading(false);
          toast.success("Success !!", {
            onClose: () => navigate("/users"),
            autoClose: 1000,
          });
        } catch (error) {
          setLoading(false);

          let message;

          if (axios.isAxiosError(error)) {
            //@ts-ignore
            message = error?.response?.data.message;
          }

          toast.error(
            `Error trying to create user${
              message ? `\nMessage: ${message}` : ""
            }`
          );
        }
      },
      validationSchema: schema,
    });

  const getRoles = useCallback(async () => {
    try {
      const { data } = await api.role().get();

      if (data) {
        setRoles(
          data.map((role) => ({ key: role.id, text: role.description }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getPermission = useCallback(async () => {
    try {
      const { data } = await api.permission().get();

      if (data) {
        setPermissions(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getRoles();
    getPermission();
  }, [getPermission, getRoles]);

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Users Register</Text>
        <TextField
          value={values.name}
          onChange={(_, value) => {
            setFieldValue("name", value);
          }}
          errorMessage={errors.name}
          placeholder="Name"
        />
        <TextField
          onChange={(_, value) => {
            setFieldValue("userName", value);
          }}
          errorMessage={errors.userName}
          placeholder="User Name"
        />
        <TextField
          onChange={(_, value) => {
            setFieldValue("password", value);
          }}
          type="password"
          errorMessage={errors.password}
          placeholder="Password"
        />
        <Separator />
        <Dropdown
          onChange={(_, value) => {
            setFieldValue("acl", { ...values.acl, roles: [value?.key] });
          }}
          placeholder="Select an option"
          //@ts-ignore
          errorMessage={errors.acl?.roles}
          options={roles}
        />
        <Separator />
        <Checkbox
          onChange={(_, checked) =>
            setFieldValue(
              "acl",
              checked
                ? {
                    ...values.acl,
                    permissions: permissions.map((item) => item.id),
                  }
                : {}
            )
          }
          label="All"
        />
        <Stack wrap styles={listPermissions} tokens={{ childrenGap: 8 }}>
          <Text variant="xLarge">WEB Permissions</Text>
          {permissions
            .filter((item) => item.description.includes("WEB"))
            .map((permission) => (
              <Checkbox
                checked={
                  !!values?.acl?.permissions?.some(
                    (item) => item === permission.id
                  )
                }
                onChange={(_, checked) =>
                  setFieldValue(
                    "acl",
                    checked
                      ? {
                          ...values.acl,
                          permissions: [
                            ...(values?.acl?.permissions || []),
                            permission.id,
                          ],
                        }
                      : {
                          ...values.acl,
                          permissions: values?.acl?.permissions.filter(
                            (item) => item !== permission.id
                          ),
                        }
                  )
                }
                key={permission.id}
                label={permission.description}
              />
            ))}
          <Separator />
          <Text variant="xLarge">APP Permissions</Text>
          {permissions
            .filter((item) => item.description.includes("APP"))
            .map((permission) => (
              <Checkbox
                checked={
                  !!values?.acl?.permissions?.some(
                    (item) => item === permission.id
                  )
                }
                onChange={(_, checked) => {
                  if (permission.name === "item_management") {
                    const query_materials = permissions.find(
                      (item) => item.name === "query_materials"
                    )?.id;
                    if (
                      !values?.acl?.permissions?.includes(query_materials || "")
                    ) {
                      show({
                        title: "Attention !!",
                        subText: "Requires permission to query materials",
                      });
                      return;
                    }
                  }
                  setFieldValue(
                    "acl",
                    checked
                      ? {
                          ...values.acl,
                          permissions: [
                            ...(values?.acl?.permissions || []),
                            permission.id,
                          ],
                        }
                      : {
                          ...values.acl,
                          permissions: values?.acl?.permissions.filter(
                            (item) => item !== permission.id
                          ),
                        }
                  );
                }}
                key={permission.id}
                label={permission.description}
              />
            ))}
        </Stack>
        {errors.acl?.permissions && (
          <Text styles={textError} variant="small">
            {errors.acl?.permissions}
          </Text>
        )}
        <PrimaryButton disabled={loading} onClick={() => handleSubmit()}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
};

export { UsersRegister };
