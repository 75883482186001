import { IButtonStyles, IDetailsListStyles } from "@fluentui/react";

export const buttonDisable: Partial<IButtonStyles> = {
  icon: {
    color: "rgb(255, 0, 0)",
  },
};

export const buttonRestore: Partial<IButtonStyles> = {
  icon: {
    color: "rgb(9, 255, 0)",
  },
};

export const list: Partial<IDetailsListStyles> = {
  root: {
    maxHeight: "70vh",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
  },
  headerWrapper: {
    position: "fixed",
    zIndex: 10,
  },
  contentWrapper: {
    paddingTop: 60,
  },
};
