import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Text,
  TextField,
  Dropdown,
  IDropdownOption,
  Separator,
  PrimaryButton,
  SearchBox,
  DropdownMenuItemType,
  ResponsiveMode,
  ISelectableOption,
  IconButton,
  TooltipHost,
  Spinner,
} from "@fluentui/react";
import { api } from "../../services";
import { IDataRegisterProduct } from "../../commonTypes";
import { useFormik } from "formik";
import schema from "./schema";
import { container, content, searchStyle } from "./styles";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router";
import { AddCategory } from "../../components/AddCategory";

const ProductRegister: React.FC = () => {
  const [categories, setCategories] = useState<IDropdownOption[]>([]);
  const [units] = useState<IDropdownOption[]>([
    { key: "un", text: "UN" },
    { key: "bag", text: "BAG" },
    { key: "box", text: "BOX" },
    { key: "five_gallon", text: "FIVE GALLON" },
    { key: "package", text: "PACKAGE" },
    { key: "bundle", text: "BUNDLE" },
    { key: "kit", text: "KIT" },
    { key: "one_gallon", text: "ONE_GALLON" },
    { key: "sheet", text: "SHEET" },
    { key: "lbs", text: "LBS" },
    { key: "ft", text: "FT" },
    { key: "in", text: "IN" },
    { key: "gallon_1_4", text: "1/4 Gallon" },
    { key: "sqft", text: "SQFT" },
    { key: "lnft", text: "LNFT" },
    { key: "set", text: "SET" },
    { key: "piece", text: "PIECE" },
    { key: "pieces", text: "PIECES" },
    { key: "pallet", text: "PALLET" },
    { key: "bucket", text: "BUCKET" },
    { key: "boxes", text: "BOXES" },
  ]);
  const [filterCategory, setFilterCategory] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);

  const navigate = useNavigate();

  const { setFieldValue, values, errors, handleSubmit } =
    useFormik<IDataRegisterProduct>({
      initialValues: { name: "" } as IDataRegisterProduct,
      onSubmit: async (data) => {
        try {
          setLoading(true);
          await api.product().post(data);

          setLoading(false);
          toast.success("Success !!", {
            onClose: () => navigate("/products"),
            autoClose: 1000,
          });
        } catch (error) {
          setLoading(false);
          let message;

          if (axios.isAxiosError(error)) {
            //@ts-ignore
            message = error?.response?.data?.message;
          }

          toast.error(
            `Error trying to create product${
              message ? `\nMessage: ${message}` : ""
            }`
          );
        }
      },
      validationSchema: schema,
    });

  const itenSearch = {
    key: "search",
    text: "Search",
    itemType: DropdownMenuItemType.Header,
  };

  const getProductsByCategory = useCallback(async () => {
    try {
      const { data } = await api.category().get();

      if (data) {
        setCategories([
          itenSearch,
          ...data.map((category) => ({
            key: category.id,
            text: category.name,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProductsByCategory();
  }, [getProductsByCategory]);

  function handleFilterCategory(item: IDropdownOption) {
    if (!filterCategory) {
      return true;
    }

    const nameCategory =
      item.key === "search" ||
      item.text.toUpperCase().includes(filterCategory.toUpperCase());

    return nameCategory;
  }

  function renderOption(
    item: ISelectableOption,
    defaultRender: (item: ISelectableOption) => void
  ) {
    if (item.key === "search") {
      return (
        <Stack horizontal>
          <SearchBox
            styles={searchStyle}
            onChange={(_, text) => {
              setFilterCategory(text);
            }}
          />
          <TooltipHost content="Add Category">
            <IconButton
              onClick={() => setHidden(false)}
              iconProps={{ iconName: "Add" }}
            />
          </TooltipHost>
        </Stack>
      );
    }

    return defaultRender(item);
  }

  useEffect(() => {
    getProductsByCategory();
  }, [getProductsByCategory, hidden]);

  return (
    <Stack styles={container}>
      <Stack tokens={{ childrenGap: 16 }} styles={content}>
        <Text variant="xxLarge">Product Register</Text>
        <Dropdown
          onChange={(_, value) => {
            setFilterCategory(undefined);
            value && setFieldValue("category", value.key);
          }}
          placeholder="Select an Category"
          errorMessage={errors.category}
          responsiveMode={ResponsiveMode.medium}
          options={categories.filter(handleFilterCategory)}
          //@ts-ignore
          onRenderOption={renderOption}
        />
        <TextField
          value={values.name}
          onChange={(_, value) => {
            setFieldValue("name", value);
          }}
          errorMessage={errors.name}
          placeholder="Product Name"
        />
        <Dropdown
          onChange={(_, value) => {
            setFilterCategory(undefined);
            value && setFieldValue("unit", value.text);
          }}
          placeholder="Select an Unit"
          errorMessage={errors.unit}
          options={units}
        />
        <Separator />
        <PrimaryButton disabled={loading} onClick={() => handleSubmit()}>
          {loading ? <Spinner /> : "SAVE"}
        </PrimaryButton>
      </Stack>
      {!hidden && <AddCategory hidden={hidden} setHidden={setHidden} />}
    </Stack>
  );
};

export { ProductRegister };
