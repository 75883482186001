import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useKits } from "../../../../../hooks/useKits";
import { api } from "../../../../../services";
import { ISelected } from "../../components/ModalList/types";
import schema from "./schema";

interface IData {
  title?: string;
  items: ISelected[];
}

export function useEdit() {
  const navigation = useNavigate();
  const { id } = useParams();
  const { kits } = useKits();

  const selectedKit = kits?.find((item) => item.id === id);

  const {
    values,
    setFieldValue,
    setFieldError,
    errors,
    handleSubmit,
    setValues,
  } = useFormik<IData>({
    initialValues: {
      items: selectedKit?.items ?? [],
      title: selectedKit?.title,
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: async (data) => {
      try {
        setLoading(true);

        await api.kit().put(data);

        setLoading(false);

        toast.success("Kit updated", {
          autoClose: 800,
          onClose: () => {
            navigation("/kits");
          },
        });
      } catch (error) {
        setLoading(false);
        toast.error("Error in create kit");
      }
    },
  });

  const [loading, setLoading] = useState(false);
  const [hiddenModal, sethiddenModal] = useState(true);

  useEffect(() => {
    if (id && kits) {
      setValues({
        title: selectedKit?.title,
        items: selectedKit?.items ?? [],
      });
    }
  }, [id, kits]);

  useEffect(() => {
    if (errors.items) {
      toast.error(errors.items, {
        autoClose: 1200,
        onClose: () => setFieldError("items", undefined),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleInput = {
    title: {
      onChange: (_: any, text: string) => setFieldValue("title", text),
      value: values.title,
      errorMessage: errors?.title,
      onFocus: () => setFieldError("title", undefined),
    },
  };

  const onSelect = (items: ISelected[]) => {
    setFieldValue("items", items);
  };

  return {
    handleCreate: handleSubmit,
    handleInput,
    loading,
    hiddenModal,
    sethiddenModal,
    items: values.items,
    onSelect,
  };
}
