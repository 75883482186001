export function normalize(str: string) {
  return str
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function compareWord(str?: string | string[], strTo?: string) {
  if (!str || !strTo) return;

  if (!Array.isArray(str)) return normalize(str).includes(normalize(strTo));

  return str.some((s) => (s ? normalize(s).includes(normalize(strTo)) : false));
}
