import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RoutesApp } from "./app.routes";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <RoutesApp />
    </BrowserRouter>
  );
};

export { Routes };
