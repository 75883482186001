import {
  IButtonStyles,
  IDetailsListStyles,
  IDialogStyles,
  IStackStyles,
} from "@fluentui/react";

export const container: Partial<IDialogStyles> = {
  main: {
    minWidth: "600px !important",
  },
};

export const buttonDisable: Partial<IButtonStyles> = {
  icon: {
    color: "rgb(164, 38, 44)",
  },
};

export const buttonRestore: Partial<IButtonStyles> = {
  icon: {
    color: "rgb(9, 255, 0)",
  },
};

export const list: Partial<IDetailsListStyles> = {
  root: {
    maxHeight: "50vh",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
  },
  headerWrapper: {
    position: "fixed",
    zIndex: 10,
  },
  contentWrapper: {
    paddingTop: 60,
  },
};

export const rowItem: Partial<IStackStyles> = {
  root: {
    alignItems: "center",
  },
};
