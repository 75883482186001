import React from "react";
import { Checkbox, Text } from "@fluentui/react";
import * as Styles from "./styles";
import { IPropsHeaderList } from "./types";

const HeaderList: React.FC<IPropsHeaderList> = ({ handleSelect }) => {
  return (
    <Styles.Container>
      <Checkbox disabled />
      <Text style={Styles.textColum1}>Product Name:</Text>
      <Text style={Styles.textColum2}>Unit</Text>
      <Text style={Styles.textColum3}>Category</Text>
      <Text style={Styles.textColum4}>Quantity</Text>
    </Styles.Container>
  );
};

export { HeaderList };
