import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Stack, Spinner, Text } from "@fluentui/react";
import { HeaderList } from "./components";
import { IPropsList } from "./types";
import * as styles from "./styles";
import { ItemList } from "./components";

export interface IHandleList {
  clearSelection: () => void;
}

const List = forwardRef<IHandleList, IPropsList>(
  ({ data, onPressEdit, onDisable }, ref) => {
    const [end, setEnd] = useState(0);
    const [selected, setSelected] = useState<any | undefined>();

    const clearSelection = useCallback(() => setSelected(undefined), []);

    useImperativeHandle(ref, () => {
      return {
        clearSelection,
      };
    });

    useEffect(() => {
      if (data) {
        setEnd(data?.length < 50 ? data?.length : 50);
      }
    }, [data]);

    function increaseEndSlice() {
      if (!data) return null;

      const total = data.length;
      const difference = total - end > 50 ? end + 50 : end + (total - end);

      setEnd((prev) => (prev >= total ? total : difference));
    }

    function handleScroll(e?: React.UIEvent<HTMLElement, globalThis.UIEvent>) {
      if (!e) {
        return null;
      }
      const scrollHeight = e?.currentTarget.scrollHeight;
      const offsetScroll = e?.currentTarget.offsetHeight;

      const totalHeightScroll = scrollHeight - offsetScroll;

      const eightyPercent = totalHeightScroll * 0.8;

      if (e.currentTarget.scrollTop > eightyPercent) {
        increaseEndSlice();
      }
    }

    const renderItems = useCallback(() => {
      if (!data) {
        return <Spinner />;
      }

      return data
        .slice(0, end)
        .map((item, index) => (
          <ItemList
            key={item.id}
            item={item}
            onPressEdit={() => onPressEdit(item.id)}
            onDisable={() => onDisable(item.id)}
          />
        ));
    }, [data, end, onDisable, onPressEdit]);

    return (
      <Stack styles={styles.stylesContainer}>
        <Stack
          styles={styles.stylesListContainer}
          tokens={styles.tokensContainer}
          onScroll={handleScroll}
        >
          <HeaderList />
          {renderItems()}
        </Stack>
        <Stack styles={styles.containerTextNumber}>
          <Text>{`Exibindo ${end} de ${data?.length}`}</Text>
        </Stack>
      </Stack>
    );
  }
);

export { List };
