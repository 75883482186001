import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  INavLink,
  INavLinkGroup,
  INavStyles,
  Nav,
  Stack,
} from "@fluentui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context";
import logoImage from "../../assets/logo_drawer.png";

const Drawer: React.FC = () => {
  const [activeRoute, setActiveRoute] = useState("/home");
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  const navStyles: Partial<INavStyles> = {
    root: {
      width: 240,
      height: "calc(100vh - 120px)",
      boxSizing: "border-box",
      border: "1px solid #eee",
      overflowY: "auto",
    },
  };
  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: "Create",
          url: "",
          links: [
            {
              name: "User",
              url: "/register/users",
              key: "/register/users",
              icon: "AddFriend",
              ariaLabel: "create_users",
            },
            {
              name: "Products",
              url: "/register/products",
              key: "/register/products",
              icon: "ProductVariant",
              ariaLabel: "create_products",
            },
            {
              name: "Kit's",
              url: "/register/kits",
              key: "/register/kits",
              icon: "ProductVariant",
              ariaLabel: "create_kit",
            },
            {
              name: "Client",
              url: "/register/clients",
              key: "/register/clients",
              icon: "ManagerSelfService",
              ariaLabel: "create_clients",
            },
            {
              name: "Process Work",
              url: "/register/process",
              key: "/register/process",
              icon: "Processing",
              ariaLabel: "create_process",
            },
          ],
          isExpanded: true,
        },
        {
          name: "Querys",
          url: "",
          links: [
            {
              name: "Users",
              url: "/users",
              key: "/users",
              icon: "People",
              ariaLabel: "query_users",
            },
            {
              name: "Products",
              url: "/products",
              key: "/products",
              icon: "ProductList",
              ariaLabel: "query_products",
            },
            {
              name: "Kit's",
              url: "/kits",
              key: "/kits",
              icon: "ProductList",
              ariaLabel: "query_kits",
            },
            {
              name: "Clients",
              url: "/clients",
              key: "/clients",
              icon: "ManagerSelfService",
              ariaLabel: "query_clients",
            },
            {
              name: "Process Work",
              url: "/process",
              key: "/process",
              icon: "Processing",
              ariaLabel: "query_process",
            },
          ],
          isExpanded: true,
        },
      ],
    },
  ];

  function handleClick(
    ev?: React.MouseEvent<HTMLElement, MouseEvent>,
    item?: INavLink
  ) {
    ev?.preventDefault();

    if (item) {
      navigate(item?.url);
    }
  }

  if (!user) {
    return null;
  }

  return (
    <Stack>
      <Stack
        tokens={{ padding: 16 }}
        styles={{ root: { alignItems: "center" } }}
      >
        <Image width={188} src={logoImage} />
      </Stack>
      <Nav
        selectedKey={activeRoute}
        groups={navLinkGroups.map((group) => {
          group.links = group.links
            .map((subGroup) => {
              subGroup.links = subGroup.links?.filter((link) =>
                user.permissions.some((p) => p.name === link.ariaLabel)
              );
              return subGroup;
            })
            .filter((item) => item?.links?.length !== 0);
          return group;
        })}
        styles={navStyles}
        onLinkClick={handleClick}
      />
    </Stack>
  );
};

export { Drawer };
